import type { Options as YoutubePlayerOptions } from "youtube-player/dist/types";

export const getPlayerOptions = ({
  adIu,
  enableAutoPlay,
  height,
  relatedChannels,
  width,
}: {
  adIu: string;
  enableAutoPlay: boolean;
  height?: string | number;
  relatedChannels: string[];
  width?: string | number;
}): YoutubePlayerOptions => ({
  embedConfig: {
    adsConfig: {
      adTagParameters: {
        iu: adIu,
      },
      disableAds: true,
      nonPersonalizedAd: true,
    },
    disabledRelatedVideos: true,
    enableIma: true,
    relatedChannels,
  },
  height,
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: enableAutoPlay ? 1 : 0,
    origin: window.location.origin,
    playsinline: 1,
    rel: 0,
  },
  width,
});
