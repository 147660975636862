import type { MouseEventHandler } from "react";
import { forwardRef } from "react";

import {
  PreviewContainer,
  PreviewContentContainer,
  PreviewContentDataContainer,
  PreviewContentFloatContainer,
  PreviewDuration,
  PreviewImage,
  PreviewPlayButtonContainer,
  PreviewTitle,
  StyledPlayButton,
} from "./styles";

export type Props = {
  duration?: string;
  imageSource?: string;
  isHeroVideo: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  title?: string;
};

export const SCMPYoutubeVideoPreview = forwardRef<HTMLDivElement, Props>(
  ({ duration, imageSource, isHeroVideo, onClick, title }, reference) => (
    <PreviewContainer ref={reference} onClick={onClick}>
      <PreviewImage $aspectRatio="16/9" $objectFit="cover" src={imageSource} />
      <PreviewContentContainer>
        <PreviewContentFloatContainer>
          <PreviewPlayButtonContainer $isHeroVideo={isHeroVideo}>
            <StyledPlayButton />
          </PreviewPlayButtonContainer>

          <PreviewContentDataContainer>
            <PreviewDuration $isHeroVideo={isHeroVideo}>{duration}</PreviewDuration>
            <PreviewTitle $isHeroVideo={isHeroVideo}>{title}</PreviewTitle>
          </PreviewContentDataContainer>
        </PreviewContentFloatContainer>
      </PreviewContentContainer>
    </PreviewContainer>
  ),
);

SCMPYoutubeVideoPreview.displayName = "SCMPYoutubeVideoPreview";
