import { css } from "@emotion/react";
import styled from "@emotion/styled";

export type ImageProps = {
  $aspectRatio: string;
  $aspectRatioDesktopUp?: string;
  $aspectRatioTabletUp?: string;
  $background?: string;
  $hasError?: boolean;
  $height?: string;
  $objectFit?: string;
  $width?: string;
};

export const StyledImage = styled.img<ImageProps>`
  display: block;

  inline-size: ${props => props.$width ?? "100%"};
  block-size: ${props => props.$height ?? "100%"};

  color: transparent;

  background: ${props =>
    props.$hasError === false ? "transparent" : props.$background ?? "#dedede"};

  object-fit: ${props => props.$objectFit ?? "none"};
  overflow: hidden;

  ${props => css`
    aspect-ratio: auto ${props.$aspectRatio};

    ${props.theme.breakpoints.up("tablet")} {
      ${props.$aspectRatioTabletUp &&
      css`
        aspect-ratio: auto ${props.$aspectRatioTabletUp};
      `};
    }
    ${props.theme.breakpoints.up("desktop")} {
      ${props.$aspectRatioDesktopUp &&
      css`
        aspect-ratio: auto ${props.$aspectRatioDesktopUp};
      `};
    }
  `}
`;
