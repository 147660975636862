import type { YouTubePlayer } from "youtube-player/dist/types";

declare global {
  interface Window {
    _cbv?: unknown[];
  }
}

export const pushPlayer = (player: YouTubePlayer) => {
  if (!window._cbv) window._cbv = [];
  window._cbv?.push(player);
};
