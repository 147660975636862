import { useAsync, useMountEffect } from "@react-hookz/web";
import axios from "axios";

import { config } from "scmp-app/data";

export const useIpGeoLocation = () => {
  const [asyncState, { execute }] = useAsync(async () => {
    const response = await axios.get<Response>(config.locationService.url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        apiKey: config.locationService.apiKey,
      },
    });

    const parsed: IpGeoLocation = {
      country: response.data["CF-IPCountry"],
      ip: response.data.IP,
    };

    return parsed;
  });
  useMountEffect(execute);

  type Response = {
    "CF-IPCountry": string;
    IP: string;
  };

  return asyncState;
};

export type IpGeoLocation = {
  country: string;
  ip: string;
};
