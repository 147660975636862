import YouTube from "@vip30/react-youtube";
import { useCallback, useEffect, useState } from "react";

type UseCheckManualPauseEffectParameters = {
  adsManager?: google.ima.AdsManager;
  isAdStarted?: boolean;
};

export const useCheckManualPauseEffect = ({
  adsManager,
  isAdStarted,
}: UseCheckManualPauseEffectParameters) => {
  const [onMouseOvered, setOnMouseOvered] = useState(false);
  const [isManualPaused, setIsManualPaused] = useState(false);

  const handleOnAdPaused = useCallback(() => {
    setIsManualPaused(onMouseOvered);
  }, [onMouseOvered]);
  const handleOnAdResumed = useCallback(() => {
    setIsManualPaused(false);
  }, []);
  useEffect(() => {
    if (!adsManager) return;
    adsManager?.addEventListener(google.ima.AdEvent.Type.PAUSED, handleOnAdPaused);
    adsManager?.addEventListener(google.ima.AdEvent.Type.RESUMED, handleOnAdResumed);
    return () => {
      adsManager?.removeEventListener(google.ima.AdEvent.Type.PAUSED, handleOnAdPaused);
      adsManager?.addEventListener(google.ima.AdEvent.Type.RESUMED, handleOnAdResumed);
    };
  }, [adsManager, handleOnAdPaused, handleOnAdResumed]);

  const handleOnYoutubePlayerStateChange = useCallback(
    ({ data }: { data: number }) => {
      if (isAdStarted) return;
      switch (data) {
        case YouTube.PlayerState.PLAYING:
          setIsManualPaused(false);
          break;
        case YouTube.PlayerState.PAUSED:
          setIsManualPaused(onMouseOvered);
          break;
      }
    },
    [onMouseOvered, isAdStarted],
  );

  const handleOnYoutubeInteractStart = useCallback(() => {
    setOnMouseOvered(true);
  }, []);
  const handleOnYoutubeInteractEnd = useCallback(() => {
    setOnMouseOvered(false);
  }, []);
  return {
    handleOnYoutubeInteractEnd,
    handleOnYoutubeInteractStart,
    handleOnYoutubePlayerStateChange,
    isManualPaused,
  };
};
