import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";

import PlayButton from "./play-btn.svg";

type Props = {
  $isHeroVideo: boolean;
};

export const PreviewImage = styled(BaseImage)`
  position: relative;

  margin: 0;

  overflow: hidden;
`;

export const PreviewContainer = styled.div`
  position: relative;

  inline-size: 100%;
  block-size: 100%;

  cursor: pointer;

  ${props => props.theme.breakpoints.up("tablet")} {
    block-size: auto;
  }
`;

export const PreviewContentContainer = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;

  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-end;

  inline-size: 100%;
  block-size: 100%;
  padding-block: 0 20px;
  padding-inline: 16px;

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));

  ${props => props.theme.breakpoints.up("tablet")} {
    block-size: 100%;
    padding-block: 0 32px;
    padding-inline: 32px;
  }
`;

export const StyledPlayButton = styled(PlayButton)``;

export const PreviewPlayButtonContainer = styled.div<Props>`
  margin-block-start: 20px;
  margin-inline-end: 12px;

  ${StyledPlayButton} {
    ${props =>
      props.$isHeroVideo
        ? css`
            inline-size: 40px;
            block-size: 40px;
            ${props.theme.breakpoints.up("tablet")} {
              inline-size: 60px;
              block-size: 60px;
            }
          `
        : css`
            inline-size: 28px;
            block-size: 28px;
            ${props.theme.breakpoints.up("tablet")} {
              inline-size: 48px;
              block-size: 48px;
            }
          `}
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    ${props =>
      props.$isHeroVideo
        ? css`
            display: flex;
            align-items: center;

            margin-block-start: 0;
            margin-inline-end: 20px;
          `
        : css`
            display: flex;
            align-items: flex-end;

            margin-block-start: 0;
            margin-inline-end: 16px;
          `}
  }
`;

export const PreviewContentFloatContainer = styled.div`
  display: flex;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 508px;
  }
`;

export const PreviewTitle = styled.p<Props>`
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontMerriweather};
  line-height: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    ${props =>
      props.$isHeroVideo
        ? css`
            font-size: 24px;
            line-height: 34px;
          `
        : css`
            font-size: 20px;
            line-height: 27px;
          `}
  }
`;

export const PreviewDuration = styled.p<Props>`
  margin-block-end: 4px;

  color: #ffca05;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 2px;

    font-size: 18px;
    line-height: 21px;

    ${props =>
      props.$isHeroVideo
        ? css`
            font-size: 18px;
            line-height: 21px;
          `
        : css`
            font-size: 16px;
            line-height: 19px;
          `}
  }
`;

export const PreviewContentDataContainer = styled.div`
  /* Override the style in content schema render */
  ${PreviewTitle}, ${PreviewDuration} {
    margin-block: 0;
  }
`;
